"use client";

import { FieldAttributes, useField, useFormikContext } from "formik";

import Field from "@ui/js/components/form/field";

export default function LeadFormField(props: FieldAttributes<any>) {
  const [_, meta] = useField(props);
  const { status } = useFormikContext();

  const error =
    meta.error ||
    (status === "error" &&
      "An error occurred while processing your request. Please try again.");

  // Use the Formik status if it is set, otherwise if there is an error message then set the status to "error"
  const state = status || (error ? "error" : undefined);

  return <Field {...props} message={error} state={state} />;
}
