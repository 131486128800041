"use client";

import { useFormikContext } from "formik";
import { useState } from "react";

import postEvent from "@server/front-end-api/postEvent";

import { Neverbounce } from "@client/components/analytics/components/neverbounce";
import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

const INPUT_ID_AND_LABEL_HTMLFOR = "email";

type NeverbounceResult = {
  response: {
    result: string; // e.g. "valid", "invalid", "unknown", etc.
    status: string; // e.g. "success", "error", etc.
  };
  _error: {
    message: string;
  };
};

type ValidationHandlers = {
  onSuccess: (
    result: NeverbounceResult,
    options: ValidationOptions,
  ) => void | string;
  onError: (error: string, options: ValidationOptions) => void | string;
};

type ValidationOptions = {
  setStatus: (status: string) => void;
  emailAddress: string;
};

export const createDefaultValidationHandlers = (): ValidationHandlers => ({
  onSuccess: (result: NeverbounceResult, options: ValidationOptions) => {
    const { setStatus, emailAddress } = options;

    if (result?.response?.result === "invalid") {
      setStatus("warning");

      postEvent("error", {
        error: {
          error_message: `Validation returned '${result?.response?.result}'.`,
          error_object: "Lead Form",
          error_type: "neverbounce_validation",
          path: window?.location?.href,
          invalid_email: emailAddress,
        },
      });

      return "The entered email appears to be invalid, please verify it is correct.";
    } else {
      return "";
    }
  },
  onError: (err: string, options: ValidationOptions) => {
    const { setStatus, emailAddress } = options;

    setStatus("error");

    postEvent("error", {
      error: {
        error_message: `Validation returned an error: ${err}`,
        error_object: "Lead Form",
        error_type: "neverbounce_validation",
        path: window?.location?.href,
        invalid_email: emailAddress,
      },
    });

    return "An error occurred while validating the email address. Please try again.";
  },
});

export default function Email() {
  const visitCookie = getVisitCookie() as Visit.Cookie;
  const [hiddenValue, setHiddenValue] = useState("");
  const {
    isValid,
    submitCount,
    setFieldValue,
    setStatus,
    submitForm,
    validateForm,
  } = useFormikContext();

  function onSubmitEmail() {
    // This is necessary to ensure that the validEmail field is updated before submitting the form
    // setFieldValue() does not work inside Formik's async validation
    validateForm().then(() => {
      if (submitCount === 0) {
        if (isValid) {
          setFieldValue("validEmail", "true");
        } else {
          setFieldValue("validEmail", "false");
        }
      } else if (submitCount > 0) {
        setFieldValue("validEmail", "");
      }
      submitForm();
    });
  }

  async function validateEmail(
    emailAddress: string,
    handlers: Partial<ValidationHandlers> = {},
  ): Promise<string> {
    // Create validation options object with component state methods
    const validationOptions: ValidationOptions = {
      setStatus,
      emailAddress,
    };

    // Merge default handlers with any provided handlers
    const defaultHandlers = createDefaultValidationHandlers();
    const { onSuccess, onError } = { ...defaultHandlers, ...handlers };

    // Reset the form status
    setStatus("");

    /**
     * Skip Neverbounce validation if:
     *  - The form has been submitted more than once.
     *  - The window object is not available (e.g. server-side rendering).
     *  - Neverbounce is not enabled.
     */
    if (
      submitCount > 0 ||
      typeof window === "undefined" ||
      !visitCookie.neverbounceEnabled
    ) {
      return ""; // No validation error
    }

    return new Promise<string>((resolve) => {
      // Perform Neverbounce validation
      // See: https://developers.neverbounce.com/reference/widget-objects-api#getvalidatepublicemail-successcallback-errorcallback
      window?._nb.api.getValidatePublic(
        emailAddress,
        (result: NeverbounceResult) => {
          const errorMessage = onSuccess(result, validationOptions);
          resolve(errorMessage || "");
        },
        (err: string) => {
          const errorMessage = onError(err, validationOptions);
          resolve(errorMessage || "");
        },
      );
    });
  }

  return (
    <>
      <Input type="hidden" name="agreesToDataSharing" />
      <Input type="hidden" name="agreesToSoftPull" />
      <Input type="hidden" name="agreesToTCPA" id="agrees-to-tcpa" />
      <Input
        type="hidden"
        name="validEmail"
        id="valid-email"
        value={hiddenValue}
      />
      <Fieldset
        dialog="We request your email to provide essential updates, timely communication, and access to your personalized debt solutions. We're committed to not spamming and keeping your email information protected."
        legend="What is your email?"
      >
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={INPUT_ID_AND_LABEL_HTMLFOR}
          label="Email"
          validate={validateEmail}
        >
          <Input
            id={INPUT_ID_AND_LABEL_HTMLFOR}
            type="email"
            name={INPUT_ID_AND_LABEL_HTMLFOR}
            className="w-full"
          />
        </Field>
      </Fieldset>
      <Button
        // This is necessary to prevent default form submission behavior
        type="button"
        onClick={onSubmitEmail}
      />
      <Neverbounce />
    </>
  );
}
